import { Checkbox, Form, Input, Radio } from 'antd'
import React from 'react'

export const TestingUniversal = ({ optionsWithDisabled, createResetValue }) => {
  return (
    <>
      <div>
        <Form.Item label='Дисклеймер'>
          <p>
            Вся сообщаемая информация носит консультативный медицинский характер
            и направлена исключительно на профилактику ВИЧ-инфекции. Я Вам задам
            ряд личных вопросов - не ради интереса, а для того, чтобы мы вместе
            оценили степень вашего риска получить ВИЧ-инфекцию. Все, о чем мы с
            вами будем говорить останется здесь. Вы сами решите с кем делиться
            полученной информацией.
          </p>
        </Form.Item>
      </div>

      <div>
        <Form.Item
          name='63_have_you_been_tested_with_us_before'
          label='Тестировались ли вы у нас ранее?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('63_have_you_been_tested_with_us_before')}
      </div>

      <div>
        <Form.Item
          name='2_1_how_did_you_know'
          label='Откуда вы узнали о тестировании?'
        >
          <Checkbox.Group>
            <Checkbox value='От знакомых'>От знакомых</Checkbox>
            <Checkbox value='Из поисковых систем'>Из поисковых систем</Checkbox>
            <Checkbox value='Из социальных сетей'>Из социальных сетей</Checkbox>
            <Checkbox value='Контекстная реклама в Яндексе'>
              Контекстная реклама в Яндексе
            </Checkbox>
            <Checkbox value='Повторное обращение'>
              Повторное обращение
            </Checkbox>
            <Checkbox>
              <Form.Item name='2_2_how_did_you_know'>
                <Input placeholder='Другое' />
              </Form.Item>
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue(['2_1_how_did_you_know', '2_2_how_did_you_know'])}
      </div>
      
      <div>
        <Form.Item
          name='68_sex_with_women_and_men'
          label='Вы практикуете секс с мужчинами, женщинами или с теми и другими?'
        >
          <Radio.Group>
            <Radio value='МСМ'>МСМ</Radio>
            <Radio value='М'>М</Radio>
            <Radio value='Ж'>Ж</Radio>
            <Radio value='ТГЛ'>ТГЛ</Radio>
            <Radio value='Другое'>Другое</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('68_sex_with_women_and_men')}
      </div>
      <div>
        <Form.Item
          name='22_diagnosed_with_syphilis'
          label='Был ли у вас ранее диагностирован сифилис?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('22_diagnosed_with_syphilis')}
      </div>

      <div>
        <Form.Item
          name='70_diagnosed_gepatit'
          label='Был ли у вас ранее диагностирован гепатит С?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('70_diagnosed_gepatit')}
      </div>

      <div>
        <Form.Item name='4_age' label='Ваш возраст?'>
          <Input placeholder='Введите возраст' />
        </Form.Item>
        {createResetValue('4_age')}
      </div>

      <div>
        <Form.Item name='28_you_education' label='Ваше образование?'>
          <Radio.Group>
            <Radio value='Среднее'>Среднее</Radio>
            <Radio value='Неоконченное высшее'>Неоконченное высшее</Radio>
            <Radio value='Высшее'>Высшее</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name='28_you_education'>
          <Input placeholder='Другое' />
        </Form.Item>
        {createResetValue('28_you_education')}
      </div>

      <div>
        <Form.Item
          name='26_you_are_a_citizen_of_russia'
          label='У вас есть гражданство РФ?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('26_you_are_a_citizen_of_russia')}
      </div>
      <div>
        <Form.Item
          name='27_1_registration_on_the_territory'
          label='У вас есть постоянная регистрация на территории проживания?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue(['27_1_registration_on_the_territory'])}
      </div>

      <div>
        <Form.Item
          name='24_how_often_hiv_tested'
          label='Как часто вы сдаете анализ/тестируетесь на ВИЧ-инфекцию?'
        >
          <Radio.Group>
            <Radio value='Раз в 3 месяца'>Раз в 3 месяца</Radio>
            <Radio value='Раз в 6 месяцев'>Раз в 6 месяцев</Radio>
            <Radio value='Раз в 12 месяцев'>Раз в 12 месяцев</Radio>
            <Radio value='Реже раза в год'>Реже раза в год</Radio>
            <Radio value='В первый раз'>В первый раз</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue(['24_how_often_hiv_tested'])}
      </div>

      <div>
        <Form.Item
          name='65_have_you_had_unprotected_sex'
          label='Был ли у вас незащищенный проникающий анальный или вагинальный секс (без презерватива) за последние 3 мес.?'
        >
          <Radio.Group>
            <Radio value='Да, был'>Да, был</Radio>
            <Radio value='Нет, не было'>Нет, не было</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('65_have_you_had_unprotected_sex')}
      </div>

      <div>
        <Form.Item
          name='66_when_do_you_use_a_condom'
          label='В каких случаях вы используете презерватив?'
        >
          <Radio.Group>
            <Radio value='Всегда использую'>Всегда использую</Radio>
            <Radio value='Иногда использую'>Иногда использую</Radio>
            <Radio value='Никогда не использую'>Никогда не использую</Radio>
            <Radio value='Только со случайными партнерами'>
              Только со случайными партнерами
            </Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('66_when_do_you_use_a_condom')}
      </div>

      <div>
        <Form.Item
          name='67_drugs_last_6_m'
          label='Был ли у вас опыт употребления психоактивных веществ за последние 6 месяцев?'
        >
          <Checkbox.Group>
            <Checkbox value='Да, неинъекционно'>Да, неинъекционно</Checkbox>
            <Checkbox value='Да, инъекционно'>Да, инъекционно</Checkbox>
            <Checkbox value='Нет'>Нет</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue('67_drugs_last_6_m')}
      </div>

      <div>
        <Form.Item
          name='17_drugs_for_sex'
          label='Используете ли вы наркотики для секса?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('17_drugs_for_sex')}
      </div>

      <div>
        <Form.Item
          name='31_you_are_a_listen_PrEP'
          label='Вы уже слышали о доконтактной профилактике (PrEP)?'
        >
          <Radio.Group>
            <Radio value='Да, слышал - не принимаю'>
              Да, слышал - не принимаю
            </Radio>
            <Radio value='Да, слышал - принимаю'>Да, слышал - принимаю</Radio>
            <Radio value='Нет, не слышал'>Нет, не слышал</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('31_you_are_a_listen_PrEP')}
      </div>

      <div>
        <Form.Item
          name='72_have_you_had_sex_for_money'
          label='За последние 12 месяцев был ли у вас секс за деньги, еду или жилье?'
        >
          <Radio.Group>
            <Radio value='Да, мне платили'>Да, мне платили</Radio>
            <Radio value='Да, я платил'>Да, я платил</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('72_have_you_had_sex_for_money')}
      </div>

      <div>
        <Form.Item
          name='69_are_you_interested_in_prep'
          label='Были бы Вы заинтересованы в приеме ДКП?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('69_are_you_interested_in_prep')}
      </div>

      <div>
        <Form.Item
          name='71_have_you_ever_taken_prep'
          label='Принимали вы когда-нибудь ПКП?'
        >
          <Radio.Group>
            <Radio value='Да'>Да</Radio>
            <Radio value='Нет'>Нет</Radio>
            <Radio value='Принимаю сейчас'>Принимаю сейчас</Radio>
            <Radio value='Принимал в течение последних 6 месяцев'>Принимал в течение последних 6 месяцев</Radio>
            <Radio value='Принимал более полугода назад'>Принимал более полугода назад</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('71_have_you_ever_taken_prep')}
      </div>

      <div>
        <Form.Item name='36_hiv_test_result' label='Результат теста на ВИЧ:'>
          <Radio.Group>
            <Radio value='Отрицательный'>Отрицательный</Radio>
            <Radio value='Положительный'>Положительный</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('36_hiv_test_result')}
      </div>

      <div>
        <Form.Item
          name='37_hepatitis_test_result'
          label={`Результат теста на Гепатит С:`}
        >
          <Radio.Group>
            <Radio value='Отрицательный'>Отрицательный</Radio>
            <Radio value='Положительный'>Положительный</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('37_hepatitis_test_result')}
      </div>
      <div>
        <Form.Item
          name='38_syphilis_test_result'
          label={`Результат теста на Сифилис:`}
        >
          <Radio.Group>
            <Radio value='Отрицательный'>Отрицательный</Radio>
            <Radio value='Положительный'>Положительный</Radio>
          </Radio.Group>
        </Form.Item>
        {createResetValue('38_syphilis_test_result')}
      </div>
      <div>
        <Form.Item
          name='39_consulting_on_regular_testing_provided'
          label='Оказано консультирование по регулярному тестированию'
        >
          <Checkbox.Group>
            <Checkbox value='Да'>Да</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue('39_consulting_on_regular_testing_provided')}
      </div>
      <div>
        <Form.Item
          name='40_prevention_counseling_provided'
          label='Оказано консультирование по профилактике'
        >
          <Checkbox.Group>
            <Checkbox value='Да'>Да</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue('40_prevention_counseling_provided')}
      </div>
      <div>
        <Form.Item
          name='41_provided_counseling_on_receiving_treatment_for_hiv'
          label='Оказано консультирование по получению лечения для ВИЧ+'
        >
          <Checkbox.Group>
            <Checkbox value='Да'>Да</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue(
          '41_provided_counseling_on_receiving_treatment_for_hiv'
        )}
      </div>

      <div>
        <Form.Item name='73_risk_group' label='Группа риска'>
        <Checkbox.Group>
            <Checkbox value='МСМ'>МСМ</Checkbox>
            <Checkbox value='СР'>СР</Checkbox>
            <Checkbox value='Химсекс'>Химсекс</Checkbox>
            <Checkbox value='Луин'>Луин</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue('73_risk_group')}
      </div>

      <div>
        <Form.Item
          name='74_redirect'
          label='Перенаправление'
        >
          <Checkbox.Group>
            <Checkbox value='Не требует перенаправления'>Не требует перенаправления</Checkbox>
            <Checkbox value='МОЦС'>МОЦС</Checkbox>
            <Checkbox value='МГЦ'>МГЦ</Checkbox>
            <Checkbox value='Региональный центр СПИДа'>Региональный центр СПИДа</Checkbox>
            <Checkbox value='Другие НКО'>Другие НКО</Checkbox>
            <Checkbox value='Дружественный врач'>Дружественный врач</Checkbox>
            <Checkbox value='Другое'>Другое</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        {createResetValue(
          '74_redirect'
        )}
      </div>
    </>
  );
};
